<template>
  <v-container fluid>
    <v-row class="mt-5 mb-2 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">MES PARRAINAGES</h2>
    </v-row>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      color="#efb639"
      :opacity="1"
    >
    </loading>
    <v-dialog v-model="dialogEdit" max-width="1000px" @click:outside="resetData">
      <v-card>
        <v-card-title>
          <span class="text-h5">Modification du client</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form ref="formEditClientInformations" v-model="validityEditFormClientInformations" lazy-validation>
              <div>
                <v-text-field
                  @input="replaceSpacesWithoutSpace"
                  prepend-inner-icon="mdi-office-building"
                  outlined
                  v-model="editCompanyInformations.siret"
                  label="N° de siret"
                ></v-text-field>
                <div>
                  <v-divider class="mb-8"></v-divider>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-inner-icon="mdi-office-building"
                        outlined
                        v-model="editCompanyInformations.name"
                        label="Nom de la compagnie"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        :rules="streetRules"
                        prepend-inner-icon="mdi-office-building"
                        outlined
                        v-model="editCompanyInformations.street"
                        label="Adresse du siège"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :rules="zipRules"
                        prepend-inner-icon="mdi-office-building"
                        outlined
                        v-model="editCompanyInformations.zip"
                        label="Code postale du siège"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        :rules="cityRules"
                        prepend-inner-icon="mdi-office-building"
                        outlined
                        v-model="editCompanyInformations.city"
                        label="Ville du siège"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="nafCodeRules"
                        prepend-inner-icon="mdi-office-building"
                        outlined
                        v-model="editCompanyInformations.naf_code"
                        label="Code NAF"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="nafLibelleRules"
                        prepend-inner-icon="mdi-office-building"
                        outlined
                        v-model="editCompanyInformations.naf_libelle"
                        label="Libellé du code NAF"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-8"></v-divider>
                  <v-row>
                    <v-simple-table class="company-table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">GENRE</th>
                            <th class="text-left">NOM</th>
                            <th class="text-left">PRENOM</th>
                            <th class="text-left">EMAIL</th>
                            <th class="text-left">FONCTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(interlocutor, index) in editCompanyInformations.interlocutors" v-bind:key="index">
                            <td class="justify-center text-center">
                              <v-edit-dialog class="edit-dialog" :return-value="value">
                                <v-text-field class="mt-5 hovered-input" :value="value" outlined dense></v-text-field>
                                <template v-slot:input>
                                  <div class="edit-dialog">
                                    <v-select :items="['M', 'MME']" v-model="interlocutor[index].gender"></v-select>
                                  </div>
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td class="justify-center text-center">
                              <v-edit-dialog class="edit-dialog" :return-value="value">
                                <v-text-field class="mt-5 hovered-input" :value="value" outlined dense></v-text-field>
                                <template v-slot:input>
                                  <div class="edit-dialog">
                                    <v-text-field
                                      outlined
                                      class="my-5"
                                      dense
                                      v-model="interlocutor[index].lastname"
                                      label="Nom de la garantie"
                                      single-line
                                    ></v-text-field>
                                  </div>
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td class="justify-center text-center">
                              <v-edit-dialog class="edit-dialog" :return-value="value">
                                <v-text-field class="mt-5 hovered-input" :value="value" outlined dense></v-text-field>
                                <template v-slot:input>
                                  <div class="edit-dialog">
                                    <v-text-field
                                      outlined
                                      class="my-5"
                                      dense
                                      v-model="interlocutor[index].firstname"
                                      label="Nom de la garantie"
                                      single-line
                                    ></v-text-field>
                                  </div>
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td class="justify-center text-center">
                              <v-edit-dialog class="edit-dialog" :return-value="value">
                                <v-text-field class="mt-5 hovered-input" :value="value" outlined dense></v-text-field>
                                <template v-slot:input>
                                  <div class="edit-dialog">
                                    <v-text-field
                                      outlined
                                      class="my-5"
                                      dense
                                      v-model="interlocutor[index].email"
                                      label="Nom de la garantie"
                                      single-line
                                    ></v-text-field>
                                  </div>
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td class="justify-center text-center">
                              <v-edit-dialog class="edit-dialog" :return-value="value">
                                <v-text-field class="mt-5 hovered-input" :value="value" outlined dense></v-text-field>
                                <template v-slot:input>
                                  <div class="edit-dialog">
                                    <v-text-field
                                      outlined
                                      class="my-5"
                                      dense
                                      v-model="interlocutor[index].quality"
                                      label="Nom de la garantie"
                                      single-line
                                    ></v-text-field>
                                  </div>
                                </template>
                              </v-edit-dialog>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    {{ editCompanyInformations.interlocutors }}

                    <v-row class="px-2">
                      <v-btn block text elevation="0" @click="addDirector" color="EoleYellow">
                        <v-icon left>mdi-plus</v-icon> Ajouter un interlocuteur
                      </v-btn>
                    </v-row>                 
                  </v-row>

                  <v-divider class="mb-8"></v-divider>
                  <v-row>
                    <v-col cols="12" md="9">
                      <v-text-field
                        :rules="sponsorCodeRules"
                        readonly
                        prepend-inner-icon="mdi-lock-reset"
                        outlined
                        v-model="editCompanyInformations.sponsor_code"
                        label="Code de parrainage"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-2">
                      <v-btn color="EoleBlue" class="white--text" @click="setEditCode()">
                        <v-icon left>mdi-update</v-icon>
                        Générer
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="EoleGreen" class="white--text" @click="validateEditFormClientInformations">
            <v-icon left>mdi-check</v-icon>
            Modifier
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mb-5 text-center my-5">
      <v-col class="d-flex" cols="12" md="6">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Entreprises dans le programme de parrainage</v-card-title>
              <v-row v-if="itemsSponsors.length > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                  <h2>{{ itemsSponsors.length }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>
                    Vous n'avez pour le moment pas de partenaires. Pour en ajouter, cliquez sur le bouton ci-dessous.
                  </p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Les partenaires approuvés correspondent aux compagnies considérées comme officiel par l'outil Mes Services
            Assurance.</span
          >
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="6">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column" link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Entreprises hors du programme de parrainage</v-card-title>
              <v-row v-if="itemsNotSponsors.length > 0" align="center">
                <v-card-text>
                  <v-icon size="48" color="EoleYellow">mdi-minus-circle</v-icon>
                  <h2>{{ itemsNotSponsors.length }}</h2>
                </v-card-text>
              </v-row>
              <v-row v-else align="center">
                <v-card-text>
                  <p>Il n'y a pas d'entreprises inactives pour le moment.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Les partenaires non approuvés sont les partenaires qui sont toujours en attente de décision. <br />
            Une fois la décision émise par les équipes de Mes Services Assurance votre partenaire en attente pourra être
            proposer dans la liste générale des partenaires de Mes Services Assurance.
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs class="mx-2" v-model="tab" style="box-shadow: none !important" background-color="transparent">
        <v-tab href="#tab-3">Professionnels</v-tab>
        <v-tab href="#tab-4">Personnels</v-tab>
      </v-tabs>
    </v-row>
    <v-tabs-items v-model="tab" style="background-color: transparent">
      <v-tab-item :value="'tab-1'">
        Salarié
      </v-tab-item>
      <v-tab-item :value="'tab-2'"> </v-tab-item>
      <v-tab-item :value="'tab-3'">
        <v-row class="my-5">
          <v-col cols="12" md="4">
            <v-card flat outlined>
              <v-card-title class="EoleBlueLighten">
                <v-col cols="12" md="10">Liste de vos entreprises</v-col>
                <v-col cols="12" md="2">
                  <v-dialog v-model="dialog" max-width="1000px" @click:outside="resetData">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="EoleBlue" outlined v-bind="attrs" v-on="on" large>
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                    </template>

                    <v-stepper v-model="stepperAddPartner">
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-stepper-items>
                        <v-stepper-content :step="1" class="no-padding">
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-form
                                  ref="formClientInformations"
                                  v-model="validityFormClientInformations"
                                  lazy-validation
                                >
                                  <div>
                                    <p>Renseignez le champs de siret pour obtenir les informations de l'entreprise</p>
                                    <v-text-field
                                      @input="replaceSpacesWithoutSpace"
                                      prepend-inner-icon="mdi-office-building"
                                      outlined
                                      v-model="siret"
                                      label="N° de siret"
                                    ></v-text-field>
                                    <div v-if="dataPappers">
                                      <v-divider class="mb-8"></v-divider>
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            :rules="nameCompanyRules"
                                            prepend-inner-icon="mdi-office-building"
                                            outlined
                                            v-model="company.name"
                                            label="Nom de la compagnie"
                                          >
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            :rules="countryRules"
                                            prepend-inner-icon="mdi-earth"
                                            outlined
                                            v-model="company.country"
                                            label="Pays du siège de la compagnie"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" md="5">
                                          <v-text-field
                                            :rules="streetRules"
                                            prepend-inner-icon="mdi-office-building"
                                            outlined
                                            v-model="company.street"
                                            label="Adresse du siège"
                                          >
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                          <v-text-field
                                            :rules="zipRules"
                                            prepend-inner-icon="mdi-office-building"
                                            outlined
                                            v-model="company.zip"
                                            label="Code postale du siège"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                          <v-text-field
                                            :rules="cityRules"
                                            prepend-inner-icon="mdi-office-building"
                                            outlined
                                            v-model="company.city"
                                            label="Ville du siège"
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            :rules="nafCodeRules"
                                            prepend-inner-icon="mdi-office-building"
                                            outlined
                                            v-model="company.naf_code"
                                            label="Code NAF"
                                          >
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-text-field
                                            :rules="nafLibelleRules"
                                            prepend-inner-icon="mdi-office-building"
                                            outlined
                                            v-model="company.naf_libelle"
                                            label="Libellé du code NAF"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-divider class="mb-8"></v-divider>
                                      <v-row class="no-padding">
                                        <v-col cols="12" md="12">
                                          <v-data-table
                                            :items="company.interlocutors"
                                            :headers="headerInterlocutor"
                                            disable-pagination
                                            hide-default-footer
                                            :no-results-text="`Aucun interlocuteur pour le moment, merci d'enregistrer un nouvel interlocuteur.`"
                                            no-data-text="Aucun interlocuteur pour le moment, merci d'enregistrer un nouvel interlocuteur."
                                          >
                                            <template v-slot:item="props">
                                              <tr>
                                                <td class="align-start">
                                                  <v-checkbox
                                                    class="mt-n2"
                                                    v-model="props.item.isSelected"
                                                    hide-details
                                                    @change="updateRecipientMail"
                                                  ></v-checkbox>
                                                </td>
                                                <td>
                                                  <v-select
                                                    ref="newTextField"
                                                    outlined
                                                    class="mt-6 hovered-input"
                                                    dense
                                                    v-model="props.item.gender"
                                                    :items="['M', 'MME']"
                                                    label="Saisir texte ici"
                                                    single-line
                                                  ></v-select>
                                                </td>
                                                <td>
                                                  <v-text-field
                                                    ref="newTextField"
                                                    outlined
                                                    class="mt-6 hovered-input"
                                                    dense
                                                    v-model="props.item.lastname"
                                                    label="Saisir texte ici"
                                                    single-line
                                                  ></v-text-field>
                                                </td>
                                                <td>
                                                  <v-text-field
                                                    ref="newTextField"
                                                    outlined
                                                    class="mt-6 hovered-input"
                                                    dense
                                                    v-model="props.item.firstname"
                                                    label="Saisir texte ici"
                                                    single-line
                                                  ></v-text-field>
                                                </td>
                                                <td>
                                                  <v-text-field
                                                    ref="newTextField"
                                                    outlined
                                                    class="mt-6 hovered-input"
                                                    dense
                                                    v-model="props.item.email"
                                                    label="Saisir texte ici"
                                                    single-line
                                                  ></v-text-field>
                                                </td>
                                                <td>
                                                  <v-text-field
                                                    ref="newTextField"
                                                    outlined
                                                    class="mt-6 hovered-input"
                                                    dense
                                                    v-model="props.item.quality"
                                                    label="Saisir texte ici"
                                                    single-line
                                                  ></v-text-field>
                                                </td>
                                                <td>
                                                  <v-btn small icon fab @click="removeInterlocutor(props.item)">
                                                    <v-icon color="EoleError">mdi-delete</v-icon>
                                                  </v-btn>
                                                </td>
                                              </tr>
                                            </template>
                                          </v-data-table>
                                        </v-col>
                                      </v-row>

                                      <v-row class="text-center justify-center mt-n7 mb-3">
                                        <v-col cols="12" md="12">
                                          <v-btn block text elevation="0" @click="addDirector" color="EoleYellow">
                                            <v-icon left>mdi-plus</v-icon> Ajouter un interlocuteur
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-divider class="mb-8"></v-divider>

                                      <v-row>
                                        <v-col cols="12" md="9">
                                          <v-text-field
                                            :rules="sponsorCodeRules"
                                            readonly
                                            prepend-inner-icon="mdi-lock-reset"
                                            outlined
                                            v-model="company.sponsorCode"
                                            label="Code de parrainage"
                                            :value="company.sponsorCode"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" class="mt-2">
                                          <v-btn color="EoleBlue" class="white--text" @click="setCode()">
                                            <v-icon left>mdi-update</v-icon>
                                            Générer
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-row class="mt-n10">
                                        <v-col cols="12" md="2"
                                          ><v-switch
                                            inset
                                            label="salarié"
                                            v-model="destinator"
                                            value="salary"
                                          ></v-switch
                                        ></v-col>
                                        <v-col cols="12" md="2">
                                          <v-switch
                                            inset
                                            label="Partenaire"
                                            v-model="destinator"
                                            value="partner"
                                          ></v-switch
                                        ></v-col>
                                        <v-col cols="12" md="5"> </v-col>
                                        <v-col cols="12" md="3" class="mt-3">
                                          <v-btn
                                            width="134px"
                                            v-if="dataPappers"
                                            color="EoleGreen"
                                            class="white--text"
                                            @click="validateFormClientInformations"
                                          >
                                            <v-icon left>mdi-check</v-icon>
                                            Valider
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </div>
                                  </div>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                v-if="!dataPappers"
                                color="EoleBlue"
                                text
                                :loading="loadingData"
                                @click="fetchPappers(siret)"
                              >
                                <v-icon left>mdi-cloud-download</v-icon> Obtenir les données
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-dialog>
                </v-col>
              </v-card-title>
              <v-card-text class="no-padding">
                <v-treeview
                  :active.sync="active"
                  :items="items"
                  :open.sync="open"
                  open-on-click
                  activatable
                  color="EoleBlue"
                  transition
                  selected-color="EoleError"
                >
                  <template v-slot:prepend="{ item }">
                    <v-avatar v-if="!item.children" size="36" class="my-2 mr-2 bordered" color="white">
                      <v-img contain :src="item.img"></v-img>
                    </v-avatar>
                  </template>
                </v-treeview>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
                <p class="text-caption">{{ itemsSponsors.length }} entreprises trouvés</p>
                <br />
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="8">
            <v-scroll-y-transition mode="out-in">
              <v-row v-if="!selected" align="center" class="text-left">
                <v-col>
                  <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                    >Veuillez selectionner une entreprise à afficher dans cette fenêtre.</v-alert
                  >
                </v-col>
              </v-row>
              <v-card v-else :key="selected.id" class="mx-auto" flat>
                <v-card outlined class="text-center">
                  <v-card-title class="EoleBlueLighten mb-5">
                    <v-row>
                      <v-col class="text-left" cols="12" md="9">
                        <span style="font-size: 1rem !important"> {{ selected.name }}</span></v-col
                      >
                      <v-col class="text-right" cols="12" md="3">
                        <v-menu left right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="editItem(selected)" link>
                              <v-list-item-title>
                                <v-icon left>mdi-pencil</v-icon>Modifier les informations</v-list-item-title
                              >
                            </v-list-item>

                            <v-list-item
                              v-if="selected.program_activated === 1"
                              color="EoleGreen"
                              link
                              @click="changeSponsorState(selected.id, 0)"
                            >
                              <v-list-item-title>
                                <v-icon color="EoleGreen" left>mdi-check</v-icon>Réactiver le programme de parrainage
                                pour
                                {{ selected.name }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else color="EoleError" link @click="changeSponsorState(selected.id, 1)">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Retirer {{ selected.name }} du
                                programme de parrainage
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-row class="ml-2" v-if="selected.program_activated != 1">
                    <v-col cols="12" md="3" class="text-left">
                      <v-text-field
                        readonly
                        outlined
                        label="Code de parrainage"
                        :value="selected.sponsor_code"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8" class="text-right mt-3"> </v-col>
                  </v-row>

                  <v-avatar id="profil-picture" v-if="pictureProfil != null" class="avatar bordered" size="128">
                    <v-img :src="pictureProfil" contain></v-img>
                    <div class="hover-overlay" v-on:click="openFileExplorer">
                      <span class="overlay-text white--text">Changer le logo</span>
                    </div>
                  </v-avatar>
                  <v-avatar v-else class="avatar" size="128" color="EoleBlue">
                    <div class="hover-overlay" v-on:click="openFileExplorer">
                      <span class="overlay-text white--text">Changer le logo</span>
                    </div>
                    <i class="fa fa-building fa-3x white--text"></i>
                  </v-avatar>
                  <h3 class="text-h5 mb-2">{{ selected.name }}</h3>
                  <h4 class="text-h6 mb-2">{{ selected.street }}, {{ selected.zip }} ({{ selected.city }})</h4>
                  <h5 class="text-h6 mb-2">{{ selected.naf_libelle }} ({{ selected.naf_code }})</h5>
                  <v-row tag="v-card-text" class="mb-1">
                    <v-col class="text-right" cols="12" md="6"> N° de siret : </v-col>
                    <v-col class="text-left">
                      <a :href="`https://www.pappers.fr/recherche?q=${selected.siret}`" target="_blank"
                        >Voir sur pappers</a
                      >
                      <v-icon right>mdi-open-in-new</v-icon>
                    </v-col>
                  </v-row>

                  <v-card-title v-if="selected.interlocutors.length > 0" class="EoleBlueLighten">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"
                        ><span style="font-size: 1rem !important"
                          >interlocuteurs de la société {{ selected.name }}</span
                        ></v-col
                      >
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          :loading="loadingMailInvitation"
                          class="white--text"
                          color="EoleBlue"
                          @click="
                            sendMailInvitation(
                              selected.id,
                              selected.type,
                              selected.interlocutors,
                              selected.sponsor_code,
                            )
                          "
                        >
                          <v-icon left>mdi-email-fast</v-icon>

                          Envoyer l'email d'invitation</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="selected.interlocutors.length > 0" class="mt-3">
                    <v-row class="text-center px-5 pt-5">
                      <v-col
                        cols="12"
                        md="4"
                        v-for="interlocutor in selected.interlocutors"
                        v-bind:key="interlocutor.id"
                        class="d-flex"
                      >
                        <v-card
                          width="250"
                          outlined
                          elevation="3"
                          class="rounded-xl align-center d-flex flex-column justify-center"
                        >
                          <v-card-text>
                            <v-avatar class="mb-5" size="64" color="EoleBlue">
                              <v-icon size="36" color="EoleBlueLighten">mdi-clipboard-account</v-icon>
                            </v-avatar>
                          </v-card-text>
                          <v-card-text class="align-center text-center">
                            <p>{{ interlocutor.lastname }} {{ interlocutor.firstname }}</p>
                            <p>{{ interlocutor.quality }}</p>
                            <p v-if="interlocutor.receiver == 1" class="EoleGreen--text">{{ interlocutor.email }}</p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="pa-5">
                      <v-card-text class="text-left"> Email envoyé le {{ selected.send_date_email }} </v-card-text>
                    </v-row>
                  </v-card-text>
                  <v-card-title class="EoleBlueLighten mt-n4">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"
                        ><span style="font-size: 1rem !important">Les produits</span></v-col
                      >
                      <v-col cols="12" md="6" class="text-right">
                        <v-dialog v-model="dialogaddProducts" max-width="1400px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="EoleBlue" class="white--text" v-bind="attrs" v-on="on">
                              <v-icon left>mdi-plus</v-icon> Ajouter un produit</v-btn
                            >
                          </template>

                          <v-card>
                            <v-card-title>
                              <span class="text-h5">Ajouter un produit</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row class="text-center pa-2">
                                  <v-col
                                    class="d-flex"
                                    cols="12"
                                    md="2"
                                    v-for="(product, index) in getProducts(selected)"
                                    v-bind:key="product.name"
                                  >
                                    <v-card
                                      v-on:click="
                                        addProduct(product)
                                        productNameList.includes(product) ? editItemReduction(product) : null
                                      "
                                      outlined
                                      elevation="3"
                                      width="150"
                                      height="200"
                                      class="align-center rounded-xl"
                                    >
                                      <v-card-text class="text-center pa-5">
                                        <i
                                          :class="[
                                            `fa-solid fa-${product.icon}`,
                                            'fa-3x',
                                            productNameList.includes(product) ? 'EoleYellow--text' : 'EoleBlue--text',
                                          ]"
                                        ></i>
                                      </v-card-text>
                                      <v-card-text>
                                        <p
                                          :class="[
                                            productNameList.includes(product) ? 'EoleYellow--text' : 'EoleBlue--text',
                                          ]"
                                        >
                                          {{ product.name.toUpperCase() }}
                                        </p>
                                        <p
                                          v-if="
                                            product.name.toUpperCase() === 'PER (PLAN D’ÉPARGNE RETRAITE INDIVIDUEL)'
                                          "
                                          class="mt-n3 text-h6 EoleYellow--text"
                                        >
                                          Frais réduits
                                        </p>
                                        <p v-if="product.reduction > 0" class="mt-n3 text-h6 EoleYellow--text">
                                          -{{ product.reduction }}%
                                        </p>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="warning" text>Annuler</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="white--text"
                                color="EoleBlue"
                                @click="postCustomerProductLinked(selected.id)"
                                >Ajouter ces produits pour {{ selected.name }}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-dialog
                      v-model="dialogReduction"
                      max-width="600px"
                      @click:outside="removeProductWithoutReduction(editReduction)"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="text-h6">Taux de réduction pour le produit {{ editReduction.name }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-text-field
                            type="number"
                            autofocus
                            v-on:keyup.enter="saveReduction"
                            v-model="editReduction.reduction"
                            suffix="%"
                          >
                          </v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="EoleBlue" text @click="dialogReduction = false">Annuler</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="EoleGreen" text @click="saveReduction">Enregistrer</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-text>
                  <v-card-text v-if="selected.products.length > 0">
                    <v-row class="text-center pa-5">
                      <v-col
                        class="d-flex"
                        cols="12"
                        md="3"
                        v-for="(product, index) in selected.products"
                        v-bind:key="index"
                      >
                        <v-card outlined elevation="3" width="200" class="align-center rounded-xl">
                          <v-row>
                            <v-col class="text-right" cols="12" md="12">
                              <v-menu left right transition="slide-x-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on" class="mr-1 mt-2 text-caption">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                  <v-list-item
                                    link
                                    @click="
                                      editReductionProductItem({
                                        id: product.id,
                                        reduction: product.reduction,
                                        idSelected: selected.id,
                                      })
                                    "
                                  >
                                    <v-list-item-title><v-icon left>mdi-pencil</v-icon>Modifier</v-list-item-title>
                                  </v-list-item>

                                  <v-list-item
                                    color="EoleError"
                                    link
                                    @click="deleteProductLinked(product.id, selected.id)"
                                  >
                                    <v-list-item-title
                                      ><v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-card-text class="text-center pa-5">
                            <i :class="['fa-solid', `fa-${product.icon}`, 'fa-3x EoleBlue--text']"></i>
                          </v-card-text>
                          <v-card-text>
                            <p :class="[productNameList.includes(product) ? 'EoleYellow--text' : 'EoleBlue--text']">
                              {{ product.name.toUpperCase() }}
                            </p>
                            <p
                              v-if="product.name.toUpperCase() === 'PER (PLAN D’ÉPARGNE RETRAITE INDIVIDUEL)'"
                              class="mt-n3 text-h6 EoleYellow--text"
                            >
                              Frais réduits
                            </p>
                            <p v-if="product.reduction > 0" class="mt-n3 text-h6 EoleYellow--text">
                              -{{ product.reduction }}%
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text v-else> Aucun produit n'est associé à ce client. </v-card-text>
                  <v-card-text>
                    <v-dialog v-model="dialogReductionProduct" max-width="600px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h6">Taux de réduction</span>
                        </v-card-title>
                        <v-card-text>
                          <v-text-field
                            type="number"
                            autofocus
                            v-on:keyup.enter="saveReductionProduct"
                            v-model="editReductionProduct.reduction"
                            suffix="%"
                          >
                          </v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="EoleBlue" text @click="dialogReductionProduct = false">Annuler</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="EoleGreen" text @click="saveReductionProduct">Enregistrer</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-text>
                  <v-card-title v-if="selected.employees.length > 0" class="EoleBlueLighten">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">Employés {{ selected.name }}</v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text v-if="selected.employees.length > 0">
                    <v-row class="pa-5">
                      <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                        lg="3"
                        v-for="employee in selected.employees"
                        v-bind:key="employee.id"
                      >
                        <v-card
                          width="200"
                          outlined
                          elevation="3"
                          class="rounded-xl align-center d-flex flex-column justify-center"
                        >
                          <v-card-text>
                            <v-avatar class="mb-5" size="64" color="EoleBlue">
                              <v-icon size="36" color="EoleBlueLighten">mdi-account</v-icon>
                            </v-avatar>
                          </v-card-text>
                          <v-card-text class="align-center text-center">
                            <p>{{ employee.lastname }} {{ employee.firstname }}</p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-title class="EoleBlueLighten mt-n5">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"
                        ><span style="font-size: 1rem !important">Page collaborateurs</span></v-col
                      >
                    </v-row>
                  </v-card-title>
                  <v-card-text class="mt-3">
                    <v-row class="pa-5">
                      <v-col cols="12" md="6" class="text-center">
                        <v-card
                          link
                          @click="testPage(selected.name, selected.id)"
                          outlined
                          elevation="3"
                          class="rounded-xl"
                          width="125"
                          height="125"
                        >
                          <v-card-text>
                            <i class="fa-solid fa-handshake fa-2x"></i>
                            <p>Lien vers la page de parrainage</p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'tab-4'"> </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
import VueApexCharts from "vue-apexcharts"

export default {
  data: () => ({
    loadingMailInvitation: false,
    dialogSendMail: false,
    recipientMail: [],
    headerInterlocutor: [
      { text: "", value: "" },

      { text: "Genre", value: "gender" },
      { text: "Nom", value: "lastname" },
      { text: "Prénom", value: "firstname" },
      { text: "Email", value: "email" },
      { text: "Fonction", value: "quality" },
      { text: "", value: "" },
    ],

    destinator: null,
    dialogReductionProduct: false,
    editReductionProduct: {
      reduction: 0,
    },
    productsAgency: [],
    validityFormClientInformations: false,
    validityEditFormClientInformations: false,
    siretRules: [
      v => !!v || "Le siret est requis",
      v => (v && v.length === 14) || "Le siret doit contenir 14 chiffres",
    ],
    nameCompanyRules: [v => !!v || "Le nom de l'entreprise est requis"],
    streetRules: [v => !!v || "La rue est requise"],
    zipRules: [
      v => !!v || "Le code postal est requis",
      v => (v && v.length === 5) || "Le code postal doit contenir 5 chiffres",
    ],
    cityRules: [v => !!v || "La ville est requise"],
    nafCodeRules: [
      v => !!v || "Le code NAF est requis",
      v => /^\d{2}\.\d{2}[A-Za-z]$/.test(v) || "Le code NAF doit être construit comme suit : XX.XX[A-Z]",
    ],
    nafLibelleRules: [v => !!v || "Le libellé NAF est requis"],
    countryRules: [v => !!v || "Le pays est requis"],
    contactGenderRules: [v => !!v || "Le genre du contact est requis"],
    contactLastnameRules: [v => !!v || "Le nom du contact est requis"],
    contactFirstnameRules: [v => !!v || "Le prénom du contact est requis"],
    contactEmailRules: [
      v => !!v || "L'adresse email du contact est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse email doit être valide",
    ],
    sponsorCodeRules: [v => !!v || "Le code de parrainage est requis"],
    contactQualityRules: [v => !!v || "La qualité du contact est requise"],
    reductionRules: [
      v => !!v || "Le taux de réduction est requis",
      v => (v && v >= 0 && v <= 100) || "Le taux de réduction doit être compris entre 0 et 100",
    ],
    sponsorCodeRules: [v => !!v || "Le code de parrainage est requis"],

    dialogaddProducts: false,
    dialogReduction: false,
    editReduction: {
      name: "",
      icon: "",
      reduction: 0,
    },
    productsArray: [],
    productNameList: [],
    tab: null,
    editCompanyInformations: {
      id: "",
      siret: "",
      name: "",
      street: "",
      zip: "",
      city: "",
      naf_code: "",
      naf_libelle: "",
      sponsorCode: "",
      interlocutors: [],
    },
    dialogEdit: false,
    editCompany: {
      sponsorCode: null,
    },
    pictureProfilUrls: {},
    itemsNotSponsors: [],
    pictureProfil: null,
    document: {
      name: null,
      data: null,
      fileId: null,
    },
    configureCenter: false,
    centerCategory: [],
    newCenter: {
      name: "",
      type_commission: "",
    },
    dialogAddCenter: false,
    headerCenters: [
      {
        text: "",
        value: "description",
      },

      {
        text: "Montant",
        value: "price",
      },
    ],
    commissionTypeCreated: [
      {
        id: 1,
        name: "Hebdomadaire",
      },
      {
        id: 2,
        name: "Trimestrielle",
      },
    ],
    categorieChoice: [],
    categoriesCreated: [
      {
        id: 1,
        name: "IARD",
        typeCommission: null,
      },
      {
        id: 2,
        name: "ASSURANCE DE PERSONNE",
        typeCommission: null,
      },
    ],
    chart: null,
    stepperCode: 0,
    apexDataCode: [],
    chartOptions2: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
    },
    chartOptions: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      labels: [],
      xaxis: {},
    },
    chartSeries: [],
    numberCodes: 0,
    stepperAddPartner: 1,
    editedPartnerInterlocutor: {
      lastname: "",
      firstname: "",
      mail: "",
      phone: "",
      function: "",
      partnerId: "",
    },
    itemsMenu: [
      {
        title: "Click Me",
      },
      {
        title: "Click Me",
      },
      {
        title: "Click Me",
      },
      {
        title: "Click Me 2",
      },
    ],
    company: {
      name: null,
      city: null,
      zip: null,
      street: null,
      siret: null,
      country: null,
      sponsorCode: null,
      naf_code: null,
      naf_libelle: null,
      interlocutors: [],
    },
    itemsSponsorsDisapproved: [],
    companySelected: "",
    alreadyExist: true,
    insurers: [],
    pappersInformations: [],
    dataPappers: false,
    siret: null,
    loadingData: false,
    interlocutors: [],
    itemsSponsors: [],
    fab: false,
    agencyName: null,
    load: false,
    /* test purpose*/
    active: [0],
    activeDisapproved: [],
    openDisapproved: [],
    avatar: null,
    users: [],
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Champs requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "N° de téléphone est composé de 10 numéro",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 8 || "Minimum 8 caractères",
    },
    emailRules: [v => !!v || "E-mail requis", v => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    waitTimeout: false,
    waitTimeoutInterlocutor: false,
    date: null,
    time: null,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    states: ["administrateur", "collaborateur"],
    dialog: false,
    dialogAddInterlocutor: false,
    dialogDelete: false,
    open: [],
    editedIndex: -1,
    defaultItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "",
      email: "",
      phoneNumber: "",
    },
    codes: [],
    headerCodes: [
      {
        text: "Nom du centre de profit",
        value: "name",
      },
    ],
    commissionerDate: null,
  }),
  computed: {
    totalValue() {
      this.codes.forEach(code => {
        code.line.forEach(line => {
          code.totalCommission += parseInt(line.value)
        })
      })
    },
    codesLineRefs() {},
    typeCommissionRefs() {
      return this.codes.map(code => ({
        ref: code.typeCommission,
        value: code.typeCommission,
      }))
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau Partenaire"
        : "Modification pour " +
            this.editedPartnerInterlocutor.lastname +
            " " +
            this.editedPartnerInterlocutor.firstname
    },
    // formTitleInterlocutor() {
    //   return this.editedIndex === -1 ? "Ajouter un nouvel interlocuteur" : "Modification"
    // },
    items() {
      return [
        {
          id: 1,
          name: `Mes entreprises (${this.itemsSponsors.length})`,
          children: this.itemsSponsors,
          open: false,
        },
        {
          id: 2,
          name: `Entreprises hors programme de parrainage (${this.itemsNotSponsors.length})`,
          children: this.itemsNotSponsors,
          open: false,
        },
      ]
    },

    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]
      const sponsor = this.itemsSponsors.find(partner => partner.id === id)
      const notSponsor = this.itemsNotSponsors.find(partner => partner.id === id)
      this.productNameList = []

      if (sponsor) {
        this.getPictureProfil(sponsor.name.replace(/\s/g, "_"))
        return sponsor
      } else if (notSponsor) {
        this.getPictureProfil(notSponsor.name.replace(/\s/g, "_"))
        return notSponsor
      } else {
        return undefined
      }
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    this.fetchCustomer()
      .then(() => {})
      .finally(() => {
        this.getPictureProfila(this.itemsSponsors)
        this.getPictureProfila(this.itemsNotSponsors)
        this.open = [1]
      })
    //this.fetchUsers()
    this.agencyName = localStorage.getItem("agency")
    // this.fetchAllProductsParticular()
    this.fetchAllProductsProfessionnal()
  },
  components: {
    appbar,
    Loading,
    apexchart: VueApexCharts,
  },

  methods: {
    async putDateSend(idProfessionnal) {
      let sendDate = moment().format("YYYY-MM-DD HH:mm:ss")
      let idAgency = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional?place=updateSendDate&idAgency=${idAgency}&idProfessional=${idProfessionnal}&sendDate=${sendDate}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        this.fetchCustomer().then(() => {
          this.open = [1]
        })
        this.dialogEdit = false
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier`, {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
    sendMailInvitation(idProfessionnal, customerType, interlocutors, sponsorCode) {
      let mails = []
      let cc = []
      this.loadingMailInvitation = true
      interlocutors.forEach(interlocutor => {
        if (interlocutor.receiver == 1) {
          mails.push(interlocutor.email)
        }
      })

      let mainEmail = mails[0]

      mails.forEach((element, index) => {
        if (index !== 0) {
          cc.push(element)
        }
      })

      if (customerType == 1) {
        emailjs
          .send("service_5p49t7p", "template_m1iqxci", {
            sponsor_code: sponsorCode,
            to_email: mainEmail,
            cc: cc,
          })
          .finally(() => {
            this.loadingMailInvitation = false
            this.putDateSend(idProfessionnal)
            this.$toast.success("Invitation envoyée avec succès", { position: "bottom-right", timeout: 3000 })
          })
          .catch(error => {
            this.loadingMailInvitation = false
            this.$toast.error("Erreur lors de l'envoi de l'invitation", { position: "bottom-right", timeout: 3000 })
          })
      } else {
        emailjs
          .send("service_5p49t7p", "template_tbgwd8k", {
            // wish: wish,
            // gender: gender,
            // lastname: lastname,
            sponsor_code: sponsorCode,
            to_email: mainEmail,
            cc: cc,
          })
          .finally(() => {
            this.loadingMailInvitation = false
            this.putDateSend(idProfessionnal)
            this.$toast.success("Invitation envoyée avec succès")
          })
          .catch(error => {
            this.loadingMailInvitation = false
            this.$toast.error("Erreur lors de l'envoi de l'invitation")
          })
      }
    },
    addDirector() {
      console.log(this.company)
      this.company.interlocutors.push({
        isSelected: false,
        gender: "",
        firstname: "",
        lastname: "",
        email: "",
        quality: "",
      })
    },

    removeInterlocutor(item) {
      this.company.interlocutors.splice(this.company.interlocutors.indexOf(item), 1)
    },

    async postCustomerProductLinked(idProfessional) {
      let formData = new FormData()

      let array = []

      this.productNameList.forEach(product => {
        array.push({
          id: product.id,
          reduction: product.reduction,
        })
      })

      formData.append("products", JSON.stringify(array))
      formData.append("idAgency", localStorage.getItem("agency_id"))
      formData.append("idProfessional", idProfessional)

      let agencyId = localStorage.getItem("agency_id")
      try {
        fetch(`${config.apiUri}/agencies/${agencyId}/customer_professional/${idProfessional}`, {
          mode: "cors",
          method: "POST",
          body: formData,
        }).finally(() => {
          this.dialogaddProducts = false
          this.fetchCustomer().then(() => {
            this.productNameList = []
            this.open = [1]
          })
        })
      } catch (e) {
        console.error(e)

        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    updateRecipientMail() {
      this.recipientMail = this.company.interlocutors.filter(item => item.isSelected).map(item => item.email)
    },

    validateFormClientInformations() {
      if (this.$refs.formClientInformations.validate()) {
        this.postCustomer()
      } else {
        this.$toast.error(`Veuillez remplir tous les champs obligatoire`, {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
    validateEditFormClientInformations() {
      if (this.$refs.formEditClientInformations.validate()) {
        if (this.editCompanyInformations.contactEmail === null) {
          this.postInterlocutor(
            this.editCompanyInformations.id,
            this.editCompanyInformations.contactGender,
            this.editCompanyInformations.contactLastname,
            this.editCompanyInformations.contactFirstname,
            this.editCompanyInformations.contactEmail,
            this.editCompanyInformations.contactQuality,
          )
        }
        this.putAllCustomerInformations(this.editCompanyInformations)
      } else {
        this.$toast.error(`Veuillez remplir tous les champs obligatoire`, {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
    // openBranch(itemId) {
    //   if (!this.open.includes(itemId)) {
    //     this.open.push(itemId)
    //   } else {
    //     this.open.splice(this.open.indexOf(itemId), 1)
    //   }
    // },
    removeProductWithoutReduction(product) {
      this.productNameList.splice(this.productNameList.indexOf(product), 1)
    },

    saveReductionProduct() {
      if (this.editReductionProduct.reduction === 0 || this.editReductionProduct.reduction > 100) {
        this.$toast.error(`Veuillez saisir une valeur entre 1 et 100`, {
          position: "bottom-right",
          duration: 5000,
        })
      } else {
        this.putProductLinked(this.editReductionProduct)
        this.dialogReductionProduct = false
        this.editReductionProduct = {}
      }
    },

    saveReduction() {
      if (this.editReduction.reduction === 0 || this.editReduction.reduction > 100) {
        this.$toast.error(`Veuillez saisir une valeur entre 1 et 100`, {
          position: "bottom-right",
          duration: 5000,
        })
      } else {
        this.dialogReduction = false
        this.editReduction = {}
      }
    },
    editItem(item) {
      this.dialogEdit = true

      this.editCompanyInformations = Object.assign({}, item)
      this.editCompanyInformations.contactGender = item.interlocutors[0].gender
      this.editCompanyInformations.contactLastname = item.interlocutors[0].lastname
      this.editCompanyInformations.contactFirstname = item.interlocutors[0].firstname
      this.editCompanyInformations.contactEmail = item.interlocutors[0].email
      this.editCompanyInformations.contactQuality = item.interlocutors[0].quality

      // this.editCompanyInformations = item
    },
    editReductionProductItem(item) {
      this.dialogReductionProduct = true
      this.editReductionProduct = item
    },
    editItemReduction(item) {
      this.dialogReduction = true
      this.editReduction = item
      // this.editCompanyInformations = item
    },
    async postInterlocutor(id, gender, lastname, firstname, email, quality) {},
    async deleteProductLinked(idProductLinked, idProfessionnal) {
      let idAgency = localStorage.getItem("agency_id")
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        const response = await fetch(
          `${config.apiUri}/agencies/${idAgency}/customer_professional/${idProfessionnal}/products/${idProductLinked}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          },
        )

        if (response.status === 400) {
        } else {
          this.$toast.success(`Le produit à bien été supprimé pour ce parrain.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          this.fetchCustomer()
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Erreur lors de la suppression.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    async putProductLinked(data) {
      const idAgency = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional?place=changeProduct&idAgency=${idAgency}&idProfessional=${data.idSelected}&idProductLinked=${data.id}&reduction=${data.reduction}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        this.fetchCustomer().then(() => {
          this.open = [1]
        })
        this.dialogEdit = false
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier`, {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
    async putAllCustomerInformations(data) {
      const idAgency = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional?place=changeInformations&idAgency=${idAgency}&idProfessional=${data.id}&zip=${data.zip}&nafCode=${data.naf_code}&nafLibelle=${data.naf_libelle}&name=${data.name}&sponsorCode=${data.sponsor_code}&address=${data.street}&siret=${data.siret}&city=${data.city}&contactGender=${data.contactGender}&contactLastname=${data.contactLastname}&contactFirstname=${data.contactFirstname}&contactEmail=${data.contactEmail}&contactQuality=${data.contactQuality}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        this.fetchCustomer().then(() => {
          this.open = [1]
        })
        this.dialogEdit = false
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier`, {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
    async putCustomer(data) {
      const idAgency = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/agencies/customer_professional?place=activeProgram&idAgency=${idAgency}&idProfessional=${data.idProfessionnal}&programActivated=${data.idState}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        this.fetchCustomer().then(() => {
          this.open = [1]
        })
        this.dialogActiveSponsor = false
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    changeSponsorState(id, stateId) {
      switch (stateId) {
        case 0:
          this.putCustomer({
            idProfessionnal: id,
            idState: stateId,
          })
          break
        case 1:
          this.putCustomer({
            idProfessionnal: id,
            idState: stateId,
          })
          break
      }
    },
    resetData() {
      this.pappersInformations = []
      this.loadingData = false

      this.company = {
        name: null,
        city: null,
        zip: null,
        street: null,
        siret: null,
        country: null,
        sponsorCode: null,
        naf_code: null,
        naf_libelle: null,
        interlocutors: [],
      }
      this.dataPappers = false
      this.siret = ""
    },

    getPictureProfil(name) {
      let agency = localStorage.getItem("agency").toLowerCase().replace(/\s/g, "_")

      try {
        let pictureProfilPng = `${config.ged}/${agency}/customers/${name
          .toLowerCase()
          .replace(/\s/g, "_")}/files/profil.png`
        let pictureProfilJpg = `${config.ged}/${agency}/customers/${name
          .toLowerCase()
          .replace(/\s/g, "_")}/files/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.pictureProfil = img.src
          // Retourner l'URL de l'image de profil
          return this.pictureProfil
        }
        img.onerror = () => {
          // Si l'image .png n'est pas disponible, essayer avec l'image .jpg
          img.onerror = null // Supprimer le gestionnaire d'erreur pour éviter une boucle
          img.src = `${pictureProfilJpg}?random=${Date.now()}`
        }

        // Charger l'image .png avec un paramètre aléatoire pour éviter le cache
        img.src = `${pictureProfilPng}?random=${Date.now()}`
      } catch (error) {
        this.pictureProfil = ""
        return null
      }
    },

    getPictureProfila(item) {
      let agency = localStorage.getItem("agency").toLowerCase().replace(/\s/g, "_")

      item.forEach(sponsor => {
        try {
          let pictureProfilPng = `${config.ged}/${agency}/customers/${sponsor.name
            .toLowerCase()
            .replace(/\s/g, "_")}/files/profil.png`
          let pictureProfilJpg = `${config.ged}/${agency}/customers/${sponsor.name
            .toLowerCase()
            .replace(/\s/g, "_")}/files/profil.jpg`

          let img = new Image()
          img.onload = () => {
            sponsor.img = img.src
            // Retourner l'URL de l'image de profil
            return sponsor.img
          }
          img.onerror = () => {
            // Si l'image .png n'est pas disponible, essayer avec l'image .jpg
            img.onerror = null // Supprimer le gestionnaire d'erreur pour éviter une boucle
            img.src = `${pictureProfilJpg}?random=${Date.now()}`
          }

          // Charger l'image .png avec un paramètre aléatoire pour éviter le cache
          img.src = `${pictureProfilPng}?random=${Date.now()}`
        } catch (error) {
          sponsor.img = ""
          return null
        }
      })
    },
    // getPictureProfil(name) {
    //   let agency = localStorage.getItem("agency").toLowerCase()

    //   try {
    //     let pictureProfilPng = `${config.ged}/${agency}/customers/${name.toLowerCase()}/files/profil.png`
    //     let pictureProfilJpg =  `${config.ged}/${agency}/customers/${name.toLowerCase()}/files/profil.png`

    //     let img = new Image()
    //     img.onload = () => {
    //       this.pictureProfil = img.src
    //     }
    //     img.onerror = () => {
    //       this.pictureProfil = null
    //     }

    //     // Test pour l'image .png
    //     img.src = pictureProfilPng

    //     // Si l'image .png n'est pas disponible, tester pour l'image .jpg
    //     setTimeout(() => {
    //       if (this.pictureProfil == null) {
    //         img.src = pictureProfilJpg
    //       }

    //     }, 500)

    //   } catch (error) {
    //     this.pictureProfil = null
    //   }

    //   // return url with picture profil
    // },
    async fetchAllProductsProfessionnal() {
      let idAgency = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          if (parseInt(element.sponsoring) === 1) {
            this.productsArray.push({
              id: element.id,
              name: element.name,
              icon: element.icon,
              id_product: element.id_product,
              reduction: 0,
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getProducts(selected) {
      let array = []
      this.productsArray.forEach(element => {
        const isPresent = selected.products.some(product => product.id_product === element.id_product)
        if (!isPresent) {
          array.push(element)
        }
      })

      return array
    },
    async fetchAllProductsParticular() {
      this.productsArray = []
      try {
        const agent = localStorage.getItem("agent_for")
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/products?clientTypology=Particulier&agent=${agent}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          let icon = ""
          switch (element.productName) {
            case "Automobile":
              icon = "fa-solid fa-car fa-3x"
              break
            case "Moto/Scooter":
              icon = "fa-solid fa-motorcycle fa-3x"
              break
            case "Emprunteur":
              icon = "fa-solid fa-building-columns fa-3x"
              break
            case "Santé":
              icon = "fa-solid fa-notes-medical fa-3x"
              break
            case "Multirisque Habitation":
              icon = "fa-solid fa-house-crack fa-3x"
              break
            default:
              icon = "fa-solid fa-eye-slash fa-3x"
              break
          }
          this.productsArray.push({
            name: element.productName,
            icon: icon,
            reduction: 0,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    addProduct(product) {
      if (this.productNameList.includes(product)) {
        product.reduction = 0
        this.productNameList.splice(this.productNameList.indexOf(product), 1)
      } else {
        this.productNameList.push(product)
      }
    },

    testPage(companyName, idCompany) {
      localStorage.setItem("company_name", companyName)
      localStorage.setItem("id_sponsor", idCompany)
      this.$router.push("/services/parrain")
    },
    async uploadPicture() {
      const file = event.target.files[0]
      // this.loadImage = true
      this.document.data = file
      let realSize = this.formatBytes(this.document.data.size)
      let object = null

      const sponsor = this.itemsSponsors.find(partner => partner.id === this.active[0])
      const notSponsor = this.itemsNotSponsors.find(partner => partner.id === this.active[0])

      if (sponsor) {
        object = this.itemsSponsors.find(partner => partner.id === this.active[0])
      } else if (notSponsor) {
        object = this.itemsNotSponsors.find(partner => partner.id === this.active[0])
      }

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "image/png":
          case "image/jpeg":
            const formData = new FormData()
            formData.append("document", this.document.data)
            formData.append("documentName", "profil")
            formData.append("customer", object.name.toLowerCase().replace(" ", "_"))
            formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
            formData.append("place", "customer")

            try {
              fetch(`${config.apiUri}/upload`, {
                method: "POST",
                body: formData,
              })
                .then(res => {
                  if (res.ok) {
                    this.dialogUpload = false
                    this.document = {
                      name: "",
                      data: null,
                      categorie: null,
                    }
                  } else {
                    alert("Erreur lors de l'upload de l'image")
                  }
                })
                .then(() => {
                  // location.reload()
                  // this.loadImage = false
                  this.getPictureProfil(object.name)
                })
                .finally(() => {
                  this.fetchCustomer()
                    .then(() => {})
                    .finally(() => {
                      this.getPictureProfila(this.itemsSponsors)

                      this.getPictureProfila(this.itemsNotSponsors)
                    })
                })
            } catch (err) {
              console.error(err)
              alert("Erreur lors de l'upload de l'image")
            }

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    openFileExplorer() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/png, image/jpeg"
      input.addEventListener("change", this.uploadPicture)
      input.click()
    },
    sendmail(sponsorCode) {
      let mainEmail = this.recipientMail[0]
      let cc = []
      this.recipientMail.forEach((element, index) => {
        if (index !== 0) {
          cc.push(element)
        }
      })

      try {
        if (this.destinator === "salary") {
          emailjs.send("service_5p49t7p", "template_m1iqxci", {
            // wish: wish,
            // gender: gender,
            // lastname: lastname,
            sponsor_code: sponsorCode,
            to_email: mainEmail,
            cc: cc,
          })
        } else if (this.destinator === "partner") {
          emailjs.send("service_5p49t7p", "template_tbgwd8k", {
            // wish: wish,
            // gender: gender,
            // lastname: lastname,
            sponsor_code: sponsorCode,
            to_email: mainEmail,
            cc: cc,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async postCustomer() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Access-Control-Allow-Origin", "*")

      let agencyId = localStorage.getItem("agency_id")
      let formData = new FormData()
      formData.append("interlocutors", JSON.stringify(this.company.interlocutors))
      formData.append("nameCompany", this.company.name)
      formData.append("phone", this.company.phone)
      formData.append("address", this.company.street)
      formData.append("postalCode", this.company.zip)
      formData.append("city", this.company.city)
      formData.append("codeNaf", this.company.naf_code)
      formData.append("libelleNaf", this.company.naf_libelle)
      formData.append("siret", this.company.siret)
      formData.append("sponsorCode", this.company.sponsorCode)
      formData.append("idAgency", agencyId)
      formData.append("type", this.destinator === "salary" ? 1 : 2)

      try {
        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/customer_professional`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
        if (response.status === 400) {
        } else {
          this.$toast.success(`Le client ${this.company.name} à bien été ajouté.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          // let wish
          // this.company.contactGender === "M" ? (wish = "Cher") : (wish = "Chère")

          // this.sendmail(
          //   // wish,
          //   // this.company.contactGender,
          //   // this.company.contactLastname,
          //   this.company.sponsorCode,
          //   // this.company.contactEmail,
          // )

          this.company = {
            name: null,
            city: null,
            zip: null,
            street: null,
            siret: null,
            country: null,
            sponsorCode: null,
            naf_code: null,
            naf_libelle: null,
            interlocutors: [],
          }
          this.recipientMail = []
          this.dataPappers = false
          this.siret = ""
        }
        this.fetchCustomer()
        this.dialog = false
      } catch (e) {
        console.log(e)
        this.$toast.error(`Erreur lors de l'envoie.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    replaceSpacesWithoutSpace() {
      this.siret = this.siret.replace(/ /g, "")
    },
    generateSponsorCode(length) {
      // generate code with 12 characters and have numbers, letters, uppercase and lowercase, and special characters
      // let code = Math.random().toString(36).slice(2)
      // this.company.sponsorCode = code
      // this.$forceUpdate()
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      let code = Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map(value => characters[value % characters.length])
        .join("")

      return code
    },
    setCode() {
      this.company.sponsorCode = this.generateSponsorCode(12)
      this.$forceUpdate()
    },
    setCodeActivation() {
      this.editCompany.sponsorCode = this.generateSponsorCode(12)
      this.$forceUpdate()
    },
    async deleteSponsor(idSponsor) {
      let idAgency = localStorage.getItem("agency_id")
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/customer_professional/${idSponsor}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })

        if (response.status === 400) {
        } else {
          this.$toast.success(`Le code parrainage à bien été supprimé.`, {
            position: "bottom-right",
            timeout: 5000,
          })
          this.fetchCustomer()
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Erreur lors de la suppression.`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    // ? Method to get informations using a PAPPERS API (https://api.pappers.fr/)
    async fetchPappers(siret) {
      this.loadingData = true
      let idAgency = localStorage.getItem("agency_id")
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      // try {
      //   const responseVerify = await fetch(
      //     `${config.apiUri}/agencies/${idAgency}/customer_professional/${siret}/verify`,
      //     {
      //       mode: "cors",
      //       method: "GET",
      //       headers: headers,
      //     },
      //   )

      //   const dataVerify = await responseVerify.json()

      //   if (dataVerify.code === 0) {
      try {
        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )

        if (response.status === 400) {
          this.siretNotFound = true
          this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
        } else {
          this.siretNotFound = false
          this.siretNotFoundMessage = ""
          let data = await response.json()

          this.pappersInformations.push(data)

          this.dataPappers = true

          this.company = {
            name: this.pappersInformations[0].denomination,
            city: this.pappersInformations[0].siege.ville,
            zip: this.pappersInformations[0].siege.code_postal,
            street: this.pappersInformations[0].siege.adresse_ligne_1,
            siret: siret,
            country: this.pappersInformations[0].siege.pays,
            naf_code: this.pappersInformations[0].code_naf,
            naf_libelle: this.pappersInformations[0].libelle_code_naf,
            interlocutors: [],
          }

          this.pappersInformations[0].representants.forEach(async element => {
            if (
              element.personne_morale === true &&
              element.qualite != "Commissaire aux comptes titulaire" &&
              element.qualite != "Commissaire aux comptes suppléant"
            ) {
              try {
                const response = await fetch(
                  `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siren=${element.siren}`,
                  {
                    mode: "cors",
                    method: "GET",
                    headers: headers,
                  },
                )
                let dataSiren = await response.json()
                console.log(dataSiren)

                dataSiren.representants.forEach(element2 => {
                  if (
                    element2.qualite != "Commissaire aux comptes titulaire" &&
                    element2.qualite != "Commissaire aux comptes suppléant"
                  ) {
                    this.company.interlocutors.push({
                      gender: "",
                      firstname: element2.prenom,
                      lastname: element2.nom,
                      email: "",
                      quality: element2.qualite,
                    })
                  }
                })
              } catch (e) {
                console.log(e)
              }
              // this.company.interlocutors.push({
              //   firstname: element.prenom,
              //   lastname: element.nom,
              //   email: element.email,
              //   phone: element.telephone,
              //   role: element.qualite,
              // })
            } else {
              // this.company.interlocutors.push({
              //   gender: "",
              //   firstname: element.prenom,
              //   lastname: element.nom,
              //   email: "",
              //   quality: element.qualite,
              // })
            }
          })
        }
        this.loadingData = false
      } catch (e) {
        console.log(e)
      }
      // } else {
      //   this.resetData()
      //   this.$toast.info("Cette société est déjà repertoriée dans notre base pour votre agence.", {
      //     position: "bottom-right",
      //     timeout: 3000,
      //   })
      // }
      // } catch (e) {
      //   this.siret = ""
      //   this.loadingData = true
      //   this.$toast.error("Impossible de vérifier l'authenticité du siret.", {
      //     position: "bottom-right",
      //     timeout: 3000,
      //   })
      // }
    },

    loaderData() {
      this.loadingData = true

      setTimeout(() => {
        this.loadingData = false
      }, 3000)
    },

    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },
    async fetchCustomer() {
      this.itemsSponsors = []
      this.itemsNotSponsors = []
      this.loading = true
      this.waitTimeout = true
      let agencyId = localStorage.getItem("agency_id")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/customer_professional`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          if (parseInt(element.program_activated) === 1) {
            this.itemsNotSponsors.push({
              id: element.id,
              name: element.commercial_name,
              siret: element.siret,
              zip: element.postal_code,
              city: element.city,
              street: element.address,
              naf_code: element.naf_code,
              naf_libelle: element.naf_libelle,
              employees: element.employees,
              interlocutors: element.interlocutors.length > 0 ? element.interlocutors : [],
              program_activated: parseInt(element.program_activated),
              products: element.products,
              type: parseInt(element.type),
              send_date_email: moment(element.send_date_email).format("Do MMMM YYYY HH:mm"),
              // interlocutors: element.interlocutors,
              // contactGender: element.interlocutors.length > 0 ? element.interlocutors[0].gender : null,
              // contactFirstname: element.interlocutors.length > 0 ? element.interlocutors[0].firstname : null,
              // contactLastname: element.interlocutors.length > 0 ? element.interlocutors[0].lastname : null,
              // contactEmail: element.interlocutors.length > 0 ? element.interlocutors[0].email : null,
              // contactQuality: element.interlocutors.length > 0 ? element.interlocutors[0].quality : null,
            })
          } else {
            this.itemsSponsors.push({
              id: element.id,
              name: element.commercial_name,
              siret: element.siret,
              zip: element.postal_code,
              city: element.city,
              street: element.address,
              naf_code: element.naf_code,
              naf_libelle: element.naf_libelle,
              sponsor_code: element.sponsor_code,
              employees: element.employees,
              interlocutors: element.interlocutors.length > 0 ? element.interlocutors : [],
              products: element.products,
              program_activated: parseInt(element.program_activated),
              type: parseInt(element.type),
              send_date_email: moment(element.send_date_email).format("Do MMMM YYYY [à] HH:mm"),

              // interlocutors: element.interlocutors,
              // contactGender: element.interlocutors.length > 0 ? element.interlocutors[0].gender : null,
              // contactFirstname: element.interlocutors.length > 0 ? element.interlocutors[0].firstname : null,
              // contactLastname: element.interlocutors.length > 0 ? element.interlocutors[0].lastname : null,
              // contactEmail: element.interlocutors.length > 0 ? element.interlocutors[0].email : null,
              // contactQuality: element.interlocutors.length > 0 ? element.interlocutors[0].quality : null,
            })
          }
        })

        this.loading = false
        this.waitTimeout = false
        this.open = [0]
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    setEditCode() {
      this.editCompanyInformations.sponsorCode = this.generateSponsorCode(12)
    },
  },
}
</script>
<style lang="scss">
.row-interlocutors {
  max-width: 450px;
}
.bordered {
  border: 1px solid #ccc;
}
.hover-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}

.avatar:hover .hover-overlay {
  opacity: 1;
}

.mullerFont {
  font-family: muller;
}

.no-padding {
  padding: 0 !important;
}

.not-allowed:hover {
  cursor: not-allowed;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

.hovered-input input {
  cursor: pointer !important;
}

.hovered-input .v-input__slot:hover {
  background-color: #e0e0e0 !important;
  transition: all 0.3s ease-in-out;
}
</style>
